<template>
  <div id="app">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          CAR DETAILS
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">
          ENTER USER DETAILS
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 3" step="3">
          GENERATE PDF
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row align="center">
            <v-col md="6" cols="12" v-if="showNumberPlatesList">
              <v-card-text class="text"
                >Number Plate : {{ number_plate2 }}</v-card-text
              >
              <v-select
                :items="plates"
                v-model="number_plate"
                :rules="[rules.required]"
                required
                solo
                outlined
                dense
                @change="loadModel"
              ></v-select>
            </v-col>
            <v-col md="6" cols="12" v-if="showNumberPlateInput"
              ><v-card-text class="text"
                >Number Plate : {{ number_plate2 }}</v-card-text
              ><v-text-field
                v-model="number_plate"
                :rules="[rules.required]"
                required
                class="text"
                dense
                solo
              ></v-text-field
            ></v-col>
            <v-col md="6" cols="12"
              ><v-card-text class="text">Car Type : {{ car_type2 }}</v-card-text
              ><v-text-field
                v-model="car_type"
                :rules="[rules.required]"
                required
                class="text"
                dense
                solo
              ></v-text-field
            ></v-col>
            <!-- <v-row align="center" style="padding-top: 2px !important">
              
            </v-row> -->
          </v-row>
          <v-row align="center" style="padding-top: 2px !important">
            <v-col md="4" cols="12" align="center">
              <v-btn dark @click="reset()" class="btnWa"> Reset </v-btn>
            </v-col>
            <v-col md="4" cols="12" align="center">
              <v-btn
                dark
                @click="loadTextBoxes()"
                class="btnWa"
                v-if="showNumberPlatesList"
              >
                Add a different car ?
              </v-btn>
              <v-btn
                dark
                @click="revertTextBoxes()"
                class="btnWa"
                v-if="showNumberPlateInput"
              >
                Pick car from list !
              </v-btn>
            </v-col>
            <v-col md="4" cols="12" align="center">
              <v-btn
                dark
                @click="step = '2'"
                :disabled="car_type == null"
                class="btnWa"
                >Next</v-btn
              >
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row align="center">
            <v-col md="4" cols="12"
              ><v-card-text class="text">Full name : {{ name2 }}</v-card-text
              ><v-text-field
                v-model="name"
                :rules="[rules.required]"
                required
                class="text"
                dense
                solo
              ></v-text-field
            ></v-col>
            <v-col md="4" cols="12"
              ><v-card-text class="text"
                >Identification/Passport Number : {{ id_number2 }}</v-card-text
              ><v-text-field
                v-model="id_number"
                :rules="[rules.required]"
                required
                class="text"
                dense
                solo
              ></v-text-field
            ></v-col>
            <v-col md="4" cols="12"
              ><v-card-text class="text"
                >Mobile Number : {{ mobileNumber2 }}</v-card-text
              ><vue-phone-number-input
                v-model="mobileNumber"
                :rules="[rules.required]"
                required
                dense
                solo
                :show-code-on-list="true"
                default-country-code="MY"
                @update="results = $event"
                :success="results?.isValid"
                style="height: 38px !important; margin-bottom: 5% !important"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col md="6" cols="12"
              ><v-card-text class="text"
                >Total Amount (RM) : {{ total_price2 }}</v-card-text
              ><v-text-field
                v-model="total_price"
                :rules="[rules.required]"
                required
                class="text"
                dense
                solo
              ></v-text-field
            ></v-col>
            <v-col md="6" cols="12"
              ><v-card-text class="text"
                >Deposit Amount (RM) : {{ deposit_price2 }}</v-card-text
              ><v-text-field
                v-model="deposit_price"
                class="text"
                dense
                solo
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row align="center">
            <!-- <div
              v-if="bookingDuration > 0"
              class="d-flex align-center justify-center"
            > -->
            <v-col align="center">
              <v-card-text
                v-if="bookingDuration > 0"
                style="
                  color: black;
                  font-size: 1rem !important;
                  font-weight: 700 !important;
                "
                >{{ bookingDuration }} day(s) selected</v-card-text
              ></v-col
            >
            <!-- </div> -->
          </v-row>
          <v-row align="center">
            <v-col cols="12" md="6">
              <v-card-text class="text">Start Date</v-card-text>
              <!-- <v-date-picker
                v-model="date_start"
                required
                :rules="[rules.required]"
                dense
                solo
                width="90%"
                height="20%"
                :value="date_start"
                @input="setStartDate"
              ></v-date-picker> -->
              <datetime
                class="theme-orange"
                type="datetime"
                v-model="date_start"
              ></datetime>
            </v-col>

            <v-col cols="12" md="6">
              <v-card-text class="text">Return Date</v-card-text>
              <!-- <v-date-picker
                v-model="date_end"
                required
                :rules="[rules.required]"
                dense
                solo
                width="90%"
                height="20%"
                @input="setEndDate"
              ></v-date-picker> -->
              <datetime
                class="theme-orange"
                type="datetime"
                v-model="date_end"
              ></datetime>
            </v-col>
          </v-row>
          <v-row class="justify-center align-center" no-gutters>
            <v-col md="6" cols="12" align="center">
              <v-btn dark @click="step = '1'" class="btnWa">Back</v-btn>
            </v-col>
            <v-btn dark @click="next()" class="btnWa"> Next </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3">
          <!-- <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1754"
            :filename="`${id_number}_${number_plate}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="auto"
            @progress="onProgress($event)"
            ref="html2Pdf"
          > -->
          <!-- <section slot="pdf-content"> -->
          <v-container
            fluid
            class="container"
            style="background-color: #f9ece0"
            ref="printcontent"
          >
            <div>
              <v-app-bar class="bar1" :elevation="11">
                <v-img
                  src="../assets/images/logo.png"
                  width="300px"
                  height="100px"
                  alt="keretasewa"
                  contain
                />
              </v-app-bar>
              <v-card color="#f9ece0">
                <v-row align="center" style="margin-bottom: 0 !important">
                  <v-col align="center">
                    <v-card-text
                      style="
                        color: black;
                        font-size: 20px !important;
                        font-weight: 700 !important;
                      "
                      >JRV RENTAL AGREEMENT</v-card-text
                    >
                  </v-col>
                </v-row>
                <v-row align="center" style="margin-bottom: 0 !important">
                  <v-col align="center">
                    <v-row align="center" style="margin-bottom: 0 !important">
                      <v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                          text-transform: uppercase !important;
                        "
                        >RENTER : {{ name }}</v-card-text
                      >
                    </v-row>
                    <v-row align="center" style="margin-bottom: 0 !important">
                      <v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                          text-transform: uppercase !important;
                        "
                        >IDENTIFICATION/PASSPORT NUMBER :
                        {{ id_number }}</v-card-text
                      >
                    </v-row>
                    <v-row align="center" style="margin-bottom: 0 !important">
                      <v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                          text-transform: uppercase !important;
                        "
                        >CAR TYPE: {{ car_type }}
                      </v-card-text>
                    </v-row>
                    <v-row align="center" style="margin-bottom: 0 !important">
                      <v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                          text-transform: uppercase !important;
                        "
                        >NUMBER PLATE: {{ number_plate }}
                      </v-card-text>
                    </v-row>
                    <v-row align="center" style="margin-bottom: 0 !important">
                      <v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                          text-transform: uppercase !important;
                        "
                        >RESERVATION DATE : {{ dateStartFormatted }} -
                        {{ dateEndFormatted }}
                      </v-card-text>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col align="center">
                    <v-card-text
                      style="
                        color: black;
                        font-size: 10px !important;
                        font-weight: 700 !important;
                      "
                      >The current agreement is presented for the rental of
                      {{ car_type }} with registration number of
                      {{ number_plate }}. The price for
                      {{ bookingDuration }} day(s) is RM {{ total_price }}. The
                      deposit RM {{ deposit_price }} will be refundable to
                      customer within 24-72 hrs from the return date if any or
                      deducted from the total amount to be paid.
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-divider
                  style="border-color: rgb(0, 0, 0) !important"
                  :thickness="20"
                  class="border-opacity-100"
                  color="success"
                ></v-divider>
                <v-row align="center">
                  <v-col align="center" style="margin-bottom: 0 !important">
                    <v-card-text
                      style="
                        color: black;
                        font-size: 20px !important;
                        font-weight: 700 !important;
                      "
                      >TERMS & CONDITIONS OF RENTAL
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row align="center" style="margin-bottom: 0 !important">
                  <v-col>
                    <v-card-text
                      style="
                        color: black;
                        font-size: 10px !important;
                        font-weight: 700 !important;
                      "
                    >
                      <ul>
                        <li>
                          Customer must provide all necessary document for
                          rental purpose.
                        </li>
                        <li>
                          Fuel level should be the same during pickup and
                          return.
                        </li>
                        <li>Car should not cross the country borders.</li>
                        <li>
                          Only registered customer and additional driver can
                          drive the vehicle.
                        </li>
                        <li>
                          Any criminal activity is prohibited, and any
                          consequences due to criminal activity will be bared by
                          customer.
                        </li>
                        <li>
                          Customers are prohibited to rent the vehicle to any
                          other 3rd party renter.
                        </li>
                        <li>
                          Drinking alcohol while driving and bringing pets in
                          the car is prohibited.
                        </li>
                        <li>
                          To extend the contract of rental, customer must notify
                          company a day earlier.
                        </li>
                        <li>No refund will be provided for early return.</li>
                        <li>
                          In cases of accident, renter must first must inform
                          the company immediately and should not SIGN any
                          authorization letter from any “call-men” unless told
                          by the company representative.
                        </li>
                        <li>
                          Renter should not use any other tow truck than the
                          ones provided by the company.
                        </li>
                        <li>
                          In cases of major accident, renter must pay 3 months
                          of rental or till the car is returned from the
                          workshop upon insurance claim completion.
                        </li>
                        <li>
                          In cases of minor accident, renter must pay for the
                          damages and the rent will continue for each day the
                          car is not out of the workshop.
                        </li>
                        <li>
                          If vehicle is under total lost, renter must pay 4
                          months of rental till the company claims the sum
                          assured from the insurance agency.
                        </li>
                        <li>
                          If cases of any wrong parking, speeding, red light
                          summon, renter will be obliged to pay the summon
                          amount in given time of period.
                        </li>
                        <li>
                          In cases whereby renter fails to pay within the given
                          date upon extension of rental, a collateral (eg :
                          Mobile Phone, any personal belongings valued within or
                          higher than amount owed), must be provided by renter
                          to the JRV Services agent in charge to be kept by the
                          company or sold by the company upon payment date due
                          or returned to renter if payment is made by renter and
                          approved by the company.
                        </li>
                      </ul>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-divider
                  style="border-color: rgb(0, 0, 0) !important"
                  :thickness="20"
                  class="border-opacity-100"
                  color="success"
                ></v-divider>
                <v-row align="center" style="padding-bottom: 59px !important">
                  <v-col align="center"
                    ><v-row align="center">
                      <v-card-text
                        style="
                          color: rgb(55, 19, 153);
                          font-size: 18px !important;
                          font-weight: 700 !important;
                          font-family: 'Best Signature Font', sans-serif;
                        "
                        >raj
                      </v-card-text>
                    </v-row>
                    <v-divider
                      style="
                        border-color: rgb(0, 0, 0) !important;
                        width: 50% !important;
                      "
                      :thickness="20"
                      class="border-opacity-100"
                      color="success"
                    ></v-divider>
                    <v-row align="center"
                      ><v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                        "
                        >AGENT SIGNATURE
                      </v-card-text></v-row
                    ></v-col
                  >
                  <v-col align="center">
                    <v-row align="center"
                      ><v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                          opacity: -1;
                        "
                        >CLIENT SIGNATURE
                      </v-card-text></v-row
                    >
                    <v-divider
                      style="
                        border-color: rgb(0, 0, 0) !important;
                        width: 50% !important;
                      "
                      :thickness="20"
                      class="border-opacity-100"
                      color="success"
                    ></v-divider>
                    <v-row align="center"
                      ><v-card-text
                        style="
                          color: black;
                          font-size: 10px !important;
                          font-weight: 700 !important;
                        "
                        >CLIENT SIGNATURE
                      </v-card-text></v-row
                    ></v-col
                  >
                </v-row>
              </v-card>
            </div>
          </v-container>
          <!-- </section> -->
          <!-- </vue-html2pdf> -->
          <v-row align="center" style="padding-top: 2px !important">
            <v-col md="6" cols="12" align="center">
              <v-btn dark @click="step = '2'" class="btnWa"> Back </v-btn>
            </v-col>
            <v-col md="6" cols="12" align="center">
              <v-btn dark @click="generatePDF()" class="btnWa"
                >Generate PDF</v-btn
              >
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
import html2pdf from "html2pdf.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import moment from "moment";

export default {
  data() {
    return {
      showNumberPlatesList: true,
      showNumberPlateInput: false,
      plates: [],
      list: [],
      step: 1,
      name: null,
      id_number: null,
      car_type: null,
      date_start: null,
      date_end: null,
      date_start_format: null,
      date_end_format: null,
      number_plate: null,
      total_price: null,
      deposit_price: 0,
      mobileNumber: null,
      results: [],
      name2: "Wah Hee Cheong",
      id_number2: "961521-05-5658",
      car_type2: "Perodua Myvi",
      number_plate2: "QS6718R",
      total_price2: "150",
      deposit_price2: "15",
      mobileNumber2: "+60 12-656 5477",
      bookings: {
        reservationDateStart: null,
        reservationDateEnd: null,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  name: "App",
  mounted() {
    if (this.$store.state.user.roles[0] == "admin") {
      this.$setLoader();
      this.getCarData();
      this.$disableLoader();
    } else {
      alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
      this.$router.push({ path: `/` });
    }
  },
  computed: {
    dateStartFormatted() {
      if (this.date_start != null) {
        return moment(this.date_start).format("LLLL");
      } else {
        return new Date();
      }
    },
    dateEndFormatted() {
      if (this.date_end != null) {
        return moment(this.date_end).format("LLLL");
      } else {
        return new Date();
      }
    },
    bookingDuration() {
      // This computed property calculates the number of days between the start and end dates.
      if (!this.$route.params.data.booking_duration) {
        if (this.date_start && this.date_end) {
          const newStart = this.date_start.slice(0, 10);
          const start = new Date(newStart);
          const newEnd = this.date_end.slice(0, 10);
          const end = new Date(newEnd);
          const duration = (end - start) / (1000 * 60 * 60 * 24);
          return Math.round(duration);
        } else {
          return 0;
        }
      } else {
        return this.$route.params.data.booking_duration;
      }
    },
  },
  methods: {
    async getCarData() {
      // if (this.$route.params) {
      if (this.$route.params.data.name) {
        this.name = this.$route.params.data.name;
        this.id_number = this.$route.params.data.id_number;
        this.car_type = this.$route.params.data.car_type;
        this.date_start = this.$route.params.data.date_start;
        this.date_end = this.$route.params.data.date_end;
        if (this.$route.params.data.mobile.startsWith("0")) {
          var temp = this.$route.params.data.mobile.substring(
            1,
            this.$route.params.data.mobile.length
          );
          this.mobileNumber = "+60" + temp;
        } else {
          this.mobileNumber = this.$route.params.data.mobile;
        }
        this.number_plate = this.$route.params.data.number_plate;
        this.total_price = this.$route.params.data.total_price;
        this.deposit_price = Number(this.$route.params.data.deposit_price);
      }
      // }
      await dataService.fetchSpecificCarData().then((res) => {
        this.list = res.data.fetchSpecificCarData;
        // this.getMakes();
        for (let carData of this.list) {
          this.plates.push(carData.plate);
        }
      });

      this.$disableLoader();
    },

    loadModel() {
      this.$setLoader();
      for (let carType of this.list) {
        if (carType.plate == this.number_plate) {
          // this.models.push(m.models);
          this.car_type = carType.type;
        }
      }
      this.$disableLoader();
    },

    reset() {
      this.car_type = null;
      this.number_plate = null;
    },

    loadTextBoxes() {
      this.showNumberPlatesList = false;
      this.showNumberPlateInput = true;
      this.car_type = null;
      this.number_plate = null;
    },

    revertTextBoxes() {
      this.showNumberPlatesList = true;
      this.showNumberPlateInput = false;
      this.car_type = null;
      this.number_plate = null;
    },

    async generatePDF() {
      this.$setLoader();
      var element = this.$refs.printcontent;
      var opt = {
        margin: 0,
        filename: `${this.name}_${this.id_number}_${this.car_type}`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      // New Promise-based usage:
      html2pdf()
        .set(opt)
        .from(element)
        .outputPdf()
        .then((pdf) => {
          this.$setLoader();
          let pdfBase64 = "data:application/pdf;base64," + btoa(pdf);
          // console.log(pdfBase64);

          var number = this.results.formattedNumber;
          number = number.replace(/[^\d]/g, "");

          var numberPlate = this.number_plate;
          numberPlate = numberPlate.split(" ").join("");
          const result = Math.random().toString(36).substring(2, 7);
          let cloudFile = {};
          cloudFile.file = pdfBase64;
          this.id_number = this.id_number.split(" ").join("");
          cloudFile.filename = `${number}_${this.id_number}_${numberPlate}_${result}`;
          this.uploadFileToCloudinary(cloudFile).then((fileResponse) => {
            // Build the Cloudinary URL with transformation parameters
            const newUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${fileResponse.public_id}.${fileResponse.format}`;
            // console.log(url);

            var mobile = `${this.results.countryCallingCode}${this.results.nationalNumber}`;

            window.open(
              `https://api.whatsapp.com/send?phone=${mobile}&text=Your%20rental%20agreement%20with%20JRV%20Car%20Rental%20Seremban%20is%20available%20here%20:%20${newUrl}`,
              "_blank",
              "noopener, noreferrer"
            );

            const newwhatsAppUrl = `https://api.whatsapp.com/send?phone=${mobile}&text=Your%20rental%20agreement%20with%20JRV%20Car%20Rental%20Seremban%20is%20available%20here%20:%20${newUrl}`;

            dataService
              .createAgreement({
                name: this.name,
                id_number: this.id_number,
                car_type: this.car_type,
                date_start: this.date_start,
                date_end: this.date_end,
                number_plate: this.number_plate,
                total_price: this.total_price,
                deposit_price: Number(this.deposit_price),
                mobile: this.results.formattedNumber,
                booking_duration: this.bookingDuration,
                creator: this.$store.state.userProfile.email,
                agreementUrl: newUrl,
                whatsappUrl: newwhatsAppUrl,
              })
              .then(() => {
                this.$disableLoader();
                this.$router.push({ name: "home" });
              });
          });
        });
      // .save();
      this.$disableLoader();
    },

    uploadFileToCloudinary(cloudFile) {
      // file.name = `${this.name}_${this.id_number}_${this.car_type}`;
      this.$setLoader();
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET1
        );
        // formData.append("resource_type", file.resource_type);
        formData.append("file", cloudFile.file);
        formData.append("public_id", cloudFile.filename);
        formData.append("folder", "Agreement");
        let request = new XMLHttpRequest();
        request.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          true
        );
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },

    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },

    setStartDate(newValue) {
      this.date_start = newValue;
      this.bookings.reservationDateStart = newValue;
    },

    setEndDate(newValue) {
      this.date_end = newValue;
      this.bookings.reservationDateEnd = newValue;
    },

    next() {
      if (
        this.name == null ||
        this.mobileNumber == null ||
        this.id_number == null ||
        this.number_plate == null ||
        this.total_price == null ||
        this.date_start == null ||
        this.date_end == null
      ) {
        alert("Missing Fields");
      } else {
        this.step = "3";
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
  components: {
    // VueHtml2pdf,
    //   ContentToPrint,
    datetime: Datetime,
  },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/best-signature-font");
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #da1010;
  width: 110px;
  background: #da1010;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  /* position: fixed; */
  /* z-index: 999; */
}
.bg {
  /* width: 100%; */
  /* height: 100%; */
  /* position: fixed; */
  top: 0;
  left: 0;
  /* background: url("https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg"); */
  /* background-size: cover; */
  /* background-color: red; */
  /* transform: scale(1.1); */
}

.container {
  /* margin-top: 5%; */
  height: fit-content !important;
}

theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #ff9800 !important;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #ff9800 !important;
}
</style>
